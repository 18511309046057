import React from 'react';
import { useState, useEffect } from 'react';
import background from './assets/background.png';
import login from './assets/icons/login.png';
import tg from './assets/icons/tg.png';
import x from './assets/icons/x.png';
import contactUs from './assets/icons/contact_us.png';
import phone from './assets/icons/phone.png';
import links from './config/links';

import alphasBlank from './assets/alphas_blank_v4.png'
import plus1 from './assets/icons/plus_1.png';
import plus2 from './assets/icons/plus_2.png';

import ContactUsModal from "./components/ContactUsModal";
import LoginModal from "./components/LoginModal";

const App = () => {
  const getPlus1Styles = () => {
    if (window.innerWidth < 768) { // Tailwind's md breakpoint is 768px
      return {
        top: '40%',
        left: '20.3%',
        width: '8%',
      };
    }
    return {
      top: '42%',
      left: '25.3%',
      width: '6%',
    };
  };

  // const getPlus2Styles = () => {
  //   if (window.innerWidth < 768) { // Tailwind's md breakpoint is 768px
  //     return {
  //       top: '27%',
  //       left: '66.5%',
  //       width: '8%',
  //     };
  //   }
  //   return {
  //     top: '28.5%',
  //     left: '64.2%',
  //     width: '6%',
  //   };
  // };

  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [plus1Styles, setPlus1Styles] = useState(getPlus1Styles());
  // const [plus2Styles, setPlus2Styles] = useState(getPlus2Styles());

  useEffect(() => {
    const handleResize = () => {
      setPlus1Styles(getPlus1Styles());
      // setPlus2Styles(getPlus2Styles());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openContactUsModal = () => setIsContactUsModalOpen(true);
  const closeContactUsModal = () => setIsContactUsModalOpen(false);
  const openLoginModal = () => setIsLoginModalOpen(true);
  const closeLoginModal = () => setIsLoginModalOpen(false);

  return (
    <div className="relative h-screen w-screen bg-cover bg-center" style={{ backgroundImage: `url(${background})` }}>
      <div className="absolute top-4 left-4 md:top-12 md:left-16 flex flex-row">
        <img src={login} alt="login" onClick={openLoginModal} onClose={closeLoginModal} className="w-40 duration-300 hover:scale-110 z-50 cursor-pointer" />
      </div>
      <div className="hidden md:flex absolute top-4 right-4 md:top-12 md:right-16  flex-row">
      <a href={links.telegram2} target="_blank" rel="noopener noreferrer" className="z-50">
          <img src={phone} alt="tg" className="w-16 md:w-24 mr-4 duration-300 hover:scale-110 z-50 cursor-pointer" />
        </a>
        <a href={links.telegram} target="_blank" rel="noopener noreferrer" className="z-50">
          <img src={tg} alt="tg" className="w-16 md:w-24 mr-4 duration-300 hover:scale-110 z-50 cursor-pointer" />
        </a>
        <a href={links.x} target="_blank" rel="noopener noreferrer" className="z-50">
          <img src={x} alt="x" className="w-16 md:w-24 duration-300 hover:scale-110 z-50 cursor-pointer" />
        </a>
      </div>

      {/* Main image section */}
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <div className="relative flex items-center justify-center">
          {/* AlphasBlank Image */}
          <img src={alphasBlank} alt="Alphas Blank" className="w-[100%] md:w-[85%] relative" />

          {/* Plus1 Positioned Absolutely */}
          <img
            src={plus1}
            alt="Plus 1"
            className="absolute heartbeat cursor-pointer z-50"
            onClick={openContactUsModal}
            style={plus1Styles}
          />

          {/* Plus2 Positioned Absolutely */}
          {/* <img
            src={plus2}
            alt="Plus 2"
            className="absolute heartbeat cursor-pointer"
            onClick={openContactUsModal}
            style={plus2Styles}
          /> */}
        </div>
        <div className="pt-12 md:pt-8">
          <img src={contactUs} alt="Contact us" onClick={openContactUsModal} className="w-64 duration-300 hover:scale-110 z-50 cursor-pointer" />
        </div>
        <div className="flex md:hidden pt-12 justify-center items-center flex-row">
        <a href={links.telegram2} target="_blank" rel="noopener noreferrer" className="z-50">
          <img src={phone} alt="tg" className="w-16 md:w-24 mr-4 duration-300 hover:scale-110 z-50 cursor-pointer" />
        </a>
        <a href={links.telegram} target="_blank" rel="noopener noreferrer" className="z-50">
          <img src={tg} alt="tg" className="w-16 md:w-24 mr-4 duration-300 hover:scale-110 z-50 cursor-pointer" />
        </a>
        <a href={links.x} target="_blank" rel="noopener noreferrer" className="z-50">
          <img src={x} alt="x" className="w-16 md:w-24 duration-300 hover:scale-110 z-50 cursor-pointer" />
        </a>
      </div>
        {/* Modal Component */}
        <ContactUsModal isOpen={isContactUsModalOpen} onClose={closeContactUsModal} />
        <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
      </div>
    </div>
  );
};

export default App;