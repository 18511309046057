import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import links from '../config/links';


const ContactUsModal = ({ isOpen, onClose }) => {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState('');

  if (!isOpen) return null;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      form.current,
      {
        publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      }
    )
      .then(
        () => {
          setStatusMessage('Message sent successfully!');
          console.log('success');
        },
        (error) => {
          setStatusMessage('Failed to send message. Please try again.');
          console.log('FAILED...', error);
        }
      );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-[rgba(32,31,38,0.5)] bg-opacity-50 p-8 mt-12 rounded-3xl relative w-[90%] max-w-[900px] md:max-w-[50vw] shadow-lg border-stone-300 border-2 lg:px-20">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-100 hover:text-gray-800"
        >
          ✖
        </button>
        <h2 className="text-center uppercase text-xl md:text-3xl text-gray-100 mb-4">
          Do you want to become a part of
        </h2>
        <h3 className="text-center uppercase text-gray-100 text-3xl md:text-6xl my-4">
          Savage Crew?
        </h3>
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
          <a href={links.savagecrew} target="_blank" rel="noopener noreferrer" className="w-full">
            <button

                className="w-full bg-purple-700 text-gray-100 py-2 rounded-md hover:bg-purple-800"
              >
                YES
            </button>
          </a>
          <button
              onClick={onClose}
              className="w-full bg-purple-700 text-gray-100 py-2 rounded-md hover:bg-purple-800"
            >
              NO
              
          </button>

        </div>
        
        {/* <form ref={form} className="space-y-4" onSubmit={sendEmail}>
          <div>
            <label className="block text-gray-100 mb-1">
              TG Username*
            </label>
            <input
              type="text"
              name="telegram_username"
              required
              className="font-input w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-700 bg-gray-200"
            />
          </div>
          <div>
            <label className="block text-gray-100 mb-1">
              E-mail*
            </label>
            <input
              type="email"
              name="email"
              required
              className="font-input  w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-700 bg-gray-200"
            />
          </div>
          <div>
            <label className="block text-gray-100 mb-1">
              Message
            </label>
            <textarea
              rows="4"
              name="message"
              className="font-input  w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-700 bg-gray-200"
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-purple-700 text-gray-100 py-2 rounded-md hover:bg-purple-800"
          >
            SEND
          </button>
          {statusMessage && (
            <p className="mt-4 text-center text-gray-100">{statusMessage}</p>
          )}
        </form> */}
      </div>
    </div>
  );
};

export default ContactUsModal;