import React, { useState, useRef } from "react";

const ContactUsModal = ({ isOpen, onClose }) => {
  const form = useRef();
  const [statusMessage, setStatusMessage] = useState('');

  if (!isOpen) return null;

  const handleSetStatusMessage = (e, message) => {
    e.preventDefault();
    setStatusMessage('You need to have a username and password 🖕');
  }


  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-[rgba(32,31,38,0.5)] bg-opacity-50 p-8 rounded-3xl relative w-[90%] max-w-[900px] md:max-w-[50vw] shadow-lg border-stone-300 border-2 lg:px-20">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-100 hover:text-gray-800"
        >
          ✖
        </button>
        <h3 className="text-center text-gray-100 text-6xl my-4">
          SAVAGE ALPHAS
        </h3>
        <form ref={form} className="space-y-4" onSubmit={handleSetStatusMessage}>
          <div>
            <label className="block text-gray-100 mb-1">
              Username
            </label>
            <input
              type="text"
              name="username"
              required
              className="font-input w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-700 bg-gray-200"
            />
          </div>
          <div>
            <label className="block text-gray-100 mb-1">
              Password
            </label>
            <input
              type="password"
              name="password"
              required
              className="font-input password w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-700 bg-gray-200"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-purple-700 text-gray-100 py-2 rounded-md hover:bg-purple-800"
          >
            LOGIN
          </button>
          {statusMessage && (
            <p className="mt-4 text-center text-gray-100">{statusMessage}</p>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactUsModal;